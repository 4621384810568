.rc-tree .rc-tree-treenode {
    display: flex;
    align-items: center;
}

.rc-tree-node-selected {
    background-color: var(--chakra-colors-blue-200);
    box-shadow: 0 0 0 1px var(--chakra-shadows-none);
    opacity: 0.8;
    border-radius: 3px;
    padding: 0 5px 0 0 !important;
}

.rc-tree-title {
    color: var(--chakra-colors-color-100);
}

.rc-tree .rc-tree-treenode span.rc-tree-switcher,
.rc-tree .rc-tree-treenode span.rc-tree-checkbox,
.rc-tree .rc-tree-treenode span.rc-tree-iconEle {
    background-image: none;
}

.rc-tree-node-selected .chakra-icon {
    color: white;
}
