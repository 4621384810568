@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    src: local(''), url('raw/rubik-v14-latin-300.woff2') format('woff2'),
        url('raw/rubik-v14-latin-300.woff') format('woff');
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('raw/rubik-v14-latin-regular.woff2') format('woff2'),
        url('raw/rubik-v14-latin-regular.woff') format('woff');
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    src: local(''), url('raw/rubik-v14-latin-500.woff2') format('woff2'),
        url('raw/rubik-v14-latin-500.woff') format('woff');
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    src: local(''), url('raw/rubik-v14-latin-600.woff2') format('woff2'),
        url('raw/rubik-v14-latin-600.woff') format('woff');
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    src: local(''), url('raw/rubik-v14-latin-700.woff2') format('woff2'),
        url('raw/rubik-v14-latin-700.woff') format('woff');
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 800;
    src: local(''), url('raw/rubik-v14-latin-800.woff2') format('woff2'),
        url('raw/rubik-v14-latin-800.woff') format('woff');
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 900;
    src: local(''), url('raw/rubik-v14-latin-900.woff2') format('woff2'),
        url('raw/rubik-v14-latin-900.woff') format('woff');
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 300;
    src: local(''), url('raw/rubik-v14-latin-300italic.woff2') format('woff2'),
        url('raw/rubik-v14-latin-300italic.woff') format('woff');
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    src: local(''), url('raw/rubik-v14-latin-italic.woff2') format('woff2'),
        url('raw/rubik-v14-latin-italic.woff') format('woff');
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 500;
    src: local(''), url('raw/rubik-v14-latin-500italic.woff2') format('woff2'),
        url('raw/rubik-v14-latin-500italic.woff') format('woff');
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 600;
    src: local(''), url('raw/rubik-v14-latin-600italic.woff2') format('woff2'),
        url('raw/rubik-v14-latin-600italic.woff') format('woff');
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 700;
    src: local(''), url('raw/rubik-v14-latin-700italic.woff2') format('woff2'),
        url('raw/rubik-v14-latin-700italic.woff') format('woff');
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 800;
    src: local(''), url('raw/rubik-v14-latin-800italic.woff2') format('woff2'),
        url('raw/rubik-v14-latin-800italic.woff') format('woff');
}

@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 900;
    src: local(''), url('raw/rubik-v14-latin-900italic.woff2') format('woff2'),
        url('raw/rubik-v14-latin-900italic.woff') format('woff');
}
